<template>
  <div>
    <notifications group="notification" position="top right" />

    <div class="row">
      <div class="col-md-4">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                @input="onUsersSelect"
                class="form-control form-control-solid form-control--owner-select"
                placeholder="Select users"
                :options="users"
                :reduce="(user) => user['email']"
                label="email"
              >
                <template #option="{ email }">
                  <span class="mr-3">{{ email }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <DatePicker
              ref="picker"
              style="width: 100%"
              locale="hr"
              v-model="range"
              is-range
              :step="1"
              :columns="$screens({ sm: 1, md: 2, lg: 3, xl: 3 })"
              :attributes="attributes"
              :masks="masks"
              is24hr
              is-expanded
              @input="onRangeSelect"
              :max-date="new Date()"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoaderVisible" class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import axios from 'axios'
import vSelect from 'vue-select'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'LoyaltyUsersTransactions',
  components: {
    vSelect,
    DatePicker,
  },
  data() {
    return {
      users: [],
      isUsersLoaded: false,
      apiEndpoint: 'https://loyalty.vendingtycoon.eu',
      range: {
        start: null,
        end: null,
      },
      masks: {
        input: 'dd.MM.yyyy - HH:mm:ss',
      },
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: null,
        },
      ],
      isLoaderVisible: false,
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Users Transactions', route: '' },
    ])

    axios.get(`${this.apiEndpoint}/api/v1/users`).then(({ data }) => {
      this.users = [...data['hydra:member']]
      this.isUsersLoaded = true
    })
  },
  methods: {
    onUsersSelect() {},
    onRangeSelect() {
      this.isLoaderVisible = true
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--owner-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
